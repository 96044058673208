import React, { useState, useRef, useCallback, useEffect, useMemo, forwardRef } from 'react';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
	Typography,
	Button,
	Chip,
	Checkbox,
	IconButton,
	Menu,
	MenuItem,
	ListItemIcon,
	Tooltip,
	Grid,
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import elasticsearch from 'elasticsearch';

import CheckboxInput from '../../components/form/inputs/CheckboxInput';
import Page from '../../components/page/Page';
import * as PageHeader from '../../components/page/PageHeader';
import Table from '../../components/Table';
import CandidateSyncModal from '../../components/modals/CandidateSyncModal';
import CandidateDeleteModal from '../../components/modals/CandidateDeleteModal';
import CandidateFilters from '../../components/filters/CandidateFilters';
import CandidateDynamicFilters from '../../components/filters/CandidateDynamicFilters';

import { ActionCreators as SiteActions } from '../../store/Site';
import * as CandidatesAPI from '../../scripts/candidates';
import * as Utility from '../../scripts/utility';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { isLocalAPI, isStagingAPI } from '../../scripts/api';
import { useContextStore } from '../../store/ContextStore';
import createElasticQuery from '../../lib/createElasticQuery';
import createDynamicElasticQuery from '../../lib/createDynamicElasticQuery';

const IS_STAGING_OR_LOCAL = isLocalAPI || isStagingAPI;

const Candidates = () => {
	const store = useStore();
	const { data, alert, user } = useSelector(state => state.site, shallowEqual);

	useDocumentTitle('Candidates | NauMatch');

	const [gridApi, setGridApi] = useState(null);
	const [gridKey, setGridKey] = useState(0);
	const [menu, setMenu] = useState({ anchor: null, candidate: {} });
	const [filter, _setFilter] = useState({ isOpen: false, filters: {} });
	// Utility.useStickyState({ isOpen: false, filters: {} }, 'admin-candidates-filter');

	const [activeSaveFilterId, setActiveSaveFilterId] = Utility.useStickyState(
		null,
		'admin-candidates-active-save-filter-id',
	);
	const [savedFilters, setSavedFilters] = Utility.useStickyState([], 'admin-candidates-saved-dynamicfiltersets');

	const { getCurrentAccessToken, enableDynamicFilters, setEnableDynamicFilters } = useContextStore();

	useEffect(() => {
		_setFilter({ isOpen: false, filters: {}, isDynamic: enableDynamicFilters });
	}, [enableDynamicFilters]);

	const [datasource, setDatasource] = useState({
		getRows: async params => {
			const { startRow, endRow, sortModel, filterModel, successCallback, failCallback, context } = params;

			// console.log({
			// 	filterModel,
			// 	sortModel,
			// 	startRow,
			// 	endRow,
			// });

			let currentFilter = null;
			_setFilter(_currentFilter => {
				currentFilter = _currentFilter;
				return _currentFilter;
			});
			const HOST_URL = IS_STAGING_OR_LOCAL
				? 'https://nauwork-portal-elastic-dev.es.westus2.azure.elastic-cloud.com'
				: 'https://nauwork-portal-elastic-prod.es.westus2.azure.elastic-cloud.com/';
			const CLOUD_ID = IS_STAGING_OR_LOCAL
				? // eslint-disable-next-line max-len
				  'nauwork-portal-elastic-dev:d2VzdHVzMi5henVyZS5lbGFzdGljLWNsb3VkLmNvbTo0NDMkZGZjMzA4MWZhMTIzNDJmNDkwZTgyMTJjOGMwNzRmYzEkYzk0OGVhNzEzMzEzNDZiODgxZGZjZDhiMjJiZDNiMjk='
				: // eslint-disable-next-line max-len
				  'nauwork-portal-elastic-prod:d2VzdHVzMi5henVyZS5lbGFzdGljLWNsb3VkLmNvbTo0NDMkZWFiODY3MDM5MWFkNGY2Yjk4MWYxMDNhZWY1MzUxOWMkODEzY2Q5Yzg2OWNkNDkzNjkwNGRlOWNmZTNhNmU5NWU=';

			const query = {
				bool: {
					must: [],
					should: [],
					filter: [],
					must_not: [],
				},
			};

			console.log('creating dynamic elastic query');

			setEnableDynamicFilters(currentValue => {
				console.log({ currentValue });
				if (currentValue) {
					createDynamicElasticQuery({
						object: 'candidate',
						currentFilter,
						query,
						objectPrefix: '',
					});
				} else {
					createElasticQuery({
						object: 'candidate',
						currentFilter,
						query,
						objectPrefix: '',
					});
				}
				return currentValue;
			});

			const currentAccessToken = await getCurrentAccessToken();
			if (currentAccessToken) {
				const config = {
					cloud: {
						id: CLOUD_ID,
					},
					host: HOST_URL, // 'https://nauwork-portal-elastic-dev.es.westus2.azure.elastic-cloud.com',
				};

				const client = new elasticsearch.Client(config);

				client
					.search({
						body: {
							size: endRow - startRow,
							from: startRow,
							sort: [
								{
									[sortModel[0]?.colId?.replace('candidates.', '')]: sortModel[0]?.sort, // 'desc',
								},
							],
							query,
						},

						index: 'candidates_index',
						headers: { Authorization: `Bearer ${currentAccessToken}` },
					})
					.then(body => {
						// eslint-disable-next-line no-underscore-dangle
						successCallback(
							// eslint-disable-next-line no-underscore-dangle
							body.hits.hits.map(x => x._source),
							body?.hits?.total?.value,
						);
					});
			}
		},
	});

	const [selectedItems, setSelectedItems] = useState([]);
	const table = useRef();

	const dataIsLoaded = data?.candidateStatuses?.length;
	// useEffect(() => {
	// 	if (gridApi && data?.candidateStatuses?.length) {
	// 		console.log('We have a gridApi and candidate status data!');
	// 		gridApi.refreshCells();
	// 	}
	// }, [data, gridApi]);

	const numFilters = Object.values(filter.filters || {}).filter(x => x).length;

	const onFilterUpdate = () => {
		if (gridApi) {
			// setGridKey(x => x + 1);
			gridApi.onFilterChanged();
		}
	};

	const setFilter = newFilter => {
		_setFilter(newFilter);
		onFilterUpdate();
	};

	const updateFilters = useCallback(
		(ev, name) => {
			if (typeof ev === 'object' && typeof name === 'string') {
				setFilter({ ...filter, filters: { ...filter.filters, [name]: ev } });
			} else if (ev.target.type === 'checkbox') {
				setFilter({
					...filter,
					filters: { ...filter.filters, [ev.target.name]: ev.target.checked ? ev.target.value : undefined },
				});
			} else if (ev.target.value !== null && ev.target.value !== undefined && ev.target.value !== '') {
				setFilter({ ...filter, filters: { ...filter.filters, [ev.target.name]: ev.target.value } });
			} else {
				setFilter({ ...filter, filters: { ...filter.filters, [ev.target.name]: undefined } });
			}
		},
		[filter, setFilter],
	);

	const filterIntakeCompleted = filter?.filters?.assessment_completed_min && filter?.filters?.assessment_completed_max;
	const setFilterIntakeCompleted = newFilterIntakeCompleted => {
		_setFilter(currentFilter => ({
			...currentFilter,
			filters: {
				...currentFilter.filters,
				assessment_completed_min: newFilterIntakeCompleted ? new Date('01/01/1970').toISOString() : undefined,
				assessment_completed_max: newFilterIntakeCompleted ? new Date('12/31/2099').toISOString() : undefined,
			},
		}));

		onFilterUpdate();
	};

	const openFilter = () => {
		_setFilter({ ...filter, isOpen: true });
	};

	const closeFilter = () => {
		_setFilter({ ...filter, isOpen: false });
	};

	const sendAssessment = ev => {
		setMenu({ ...menu, anchor: null });

		CandidatesAPI.sendAssessment(menu.candidate?.id, response => {
			if (!response) {
				store.dispatch(SiteActions.showAlert('The skills assessment could not be sent. Please try again.', 'error'));
				return;
			}

			store.dispatch(SiteActions.showAlert('A portal invitation was emailed.', 'success'));
		});
	};

	const [tableData, setTableData] = useState([]);

	// const headers = [
	// 	{ id: 'options', value: '', noSort: true },
	// 	{ id: 'select', value: '', noSort: true },
	// 	{ id: 'candidates.vendor_candidate_id', value: 'ID', noSort: false },
	// 	{ id: 'candidates.first_name', value: 'Candidate' },
	// 	{ id: 'candidates.professional_experience_years', value: 'Experience' },
	// 	{ id: 'specialties.name', value: 'Specialty' },
	// 	{ id: 'candidates.desired_position', value: 'Desired Position' },
	// 	{ id: 'leadership_experience_roles.label', value: 'Leadership' },
	// 	{ id: 'candidates.domain', value: 'Land the Job Skills', noSort: true },
	// 	{ id: 'candidates.city', value: 'Location Preference', noSort: true },
	// 	{ id: 'owners.name', value: 'Owner' },
	// 	{ id: 'candidate_statuses.name', value: 'Status' },
	// 	{ id: 'candidates.updated_at', value: 'Last Updated' },
	// 	{ id: 'candidates.assessment_completed_at', value: 'Intake Completed' },
	// 	{ id: 'candidates.shared_match_count', value: 'Shared Matches' },
	// ];
	// if (process.env.REACT_APP_FEATURE_MATCHING) {
	// 	headers.push({ id: 'candidates.matches', value: 'Matches', noSort: true });
	// }
	const defaultColDef = useMemo(() => ({
		sortable: true,
	}));

	const gridRef = useRef(); // Optional - for accessing Grid's API
	// const [columnDefs, setColumnDefs] = useState([
	const columnDefs = [
		{
			field: 'options',
			fieldName: 'Options',
			// value: '',
			// noSort: true,
			width: 50,
			headerName: '',
			cellRenderer: ({ data: candidate, node }) => {
				if (!node.id) {
					return null;
				}

				return (
					<IconButton
						key={candidate?.id}
						style={{}}
						onClick={ev => {
							setMenu({ anchor: ev.currentTarget.parentElement, candidate });
						}}
					>
						<Icons.MoreVert color="primary" />
					</IconButton>
				);
			},
		},
		// {
		// 	field: 'select',
		// 	noSort: true,
		// 	headerName: '',
		// 	width: 50,
		// 	cellRenderer: cellRendererParam => {
		// 		const { data: candidate, node } = cellRendererParam;

		// 		if (!candidate || !node) {
		// 			return null;
		// 		}

		// 		return (
		// 			<>
		// 				<CheckboxInput
		// 					style={{ marginRight: -15 }}
		// 					color="primary"
		// 					checked={selectedItems.includes(candidate?.id)}
		// 					// onChange={() => {}}
		// 					onClick={() => {
		// 						if (selectedItems.includes(candidate?.id)) {
		// 							setSelectedItems(selectedItems.filter(x => x !== candidate?.id));
		// 						} else {
		// 							setSelectedItems([...selectedItems, candidate?.id]);
		// 						}
		// 					}}
		// 				/>
		// 			</>
		// 		);
		// 	},
		// },
		{
			field: 'candidates.vendor_candidate_id',
			// fieldName: 'Vendor ID',
			// value: 'ID',
			// noSort: false,
			headerName: 'ID',
			sortable: true,
			width: 100,
			cellRenderer: forwardRef(({ data: candidate, node }, ref) => {
				if (!candidate || !node) {
					return null;
				}

				return <span ref={ref}>{candidate?.vendor_candidate_id || 'N/A'}</span>;
			}),
		},
		{
			field: 'candidates.first_name',
			value: 'Candidate',
			headerName: 'Candidate',
			sortable: false,
			resizable: true,
			width: 200,
			cellRenderer: ({ data: candidate, node }, ref) => {
				if (!node.id) {
					return <p style={{ fontStyle: 'italic', opacity: 0.6 }}>Loading...</p>;
				}
				if (!candidate || !node) {
					return null;
				}

				return (
					<>
						<div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<NavLink
									to={`/candidate/${candidate?.id}`}
									className="color-inherit"
									target="_blank"
									style={{ minWidth: 'max-content' }}
								>
									{`${candidate?.first_name} ${candidate?.last_name}`}
								</NavLink>
								<div style={{ marginLeft: 10 }}>
									<Icons.Share style={{ width: 14, color: candidate?.is_sharable ? '#00bb00' : '#ff2222' }} />
								</div>
							</div>
							{candidate?.user?.claimed_at ? (
								<span className="color-muted">Account: {Utility.formatDate(candidate?.user?.claimed_at)}</span>
							) : null}
						</div>
					</>
				);
			},
		},
		{
			field: 'candidates.professional_experience_years',
			headerName: 'Experience',
			sortable: true,
			resizable: true,
			width: 100,
			cellRenderer: ({ data: candidate, node }, ref) => {
				if (!candidate || !node) {
					return null;
				}

				return (
					<>
						{candidate?.professional_experience_years
							? `${candidate?.professional_experience_years} Years`
							: Utility.na}
					</>
				);
			},
		},
		{
			field: 'specialties.name',
			headerName: 'Specialty',
			sortable: true,
			resizable: true,
			width: 200,
			cellRenderer: ({ data: candidate, node }, ref) => {
				if (!candidate || !node) {
					return null;
				}

				return (
					<>
						{candidate?.specialties?.map(specialty => {
							const categoryId = specialty?.category_id || specialty?.category?.id;
							return (
								<span>
									{Utility.getItemLabel(data.specialties, specialty?.id)}{' '}
									<span className="color-muted">{Utility.getItemLabel(data.categories, categoryId) || Utility.nb}</span>
								</span>
							);
						})}
					</>
				);
			},
		},
		{
			field: 'candidates.desired_position',
			headerName: 'Desired Position',
			sortable: true,
			resizable: true,
			width: 200,
			cellRenderer: ({ data: candidate, node }, ref) => {
				if (!candidate || !node) {
					return null;
				}

				return (
					<>
						{candidate?.desired_position || Utility.na}
						<br />
						<span className="color-muted">
							{Utility.getItemLabel(data.employmentPreferences, candidate?.employment_preference)}
						</span>
					</>
				);
			},
		},
		{
			field: 'leadership_experience_roles.label',
			headerName: 'Leadership',
			sortable: false,
			resizable: true,
			width: 200,
			cellRenderer: ({ data: candidate, node }, ref) => {
				if (!candidate || !node) {
					return null;
				}

				return (
					<>
						{Utility.getItemLabel(data.leadershipExperienceRoles, candidate?.recent_leadership_role_id) || Utility.na}
						<br />
						<span className="color-muted">
							{candidate?.total_leadership_years ? `${candidate?.total_leadership_years} Years` : null}
						</span>
					</>
				);
			},
		},
		{
			field: 'candidates.domain',
			headerName: 'Land the Job Skills',
			sortable: false,
			resizable: true,
			width: 200,
			cellRenderer: ({ data: candidate, node }, ref) => {
				if (!candidate || !node) {
					return null;
				}

				return (
					<>
						{candidate?.candidate_skills
							?.filter(s => s.get_the_job)
							.map((s, i, a) => (
								<span key={s?.skill?.label || i}>
									<Tooltip
										className="tooltip"
										title={`${s?.experience_years || 0} Year${s?.experience_years !== 1 ? 's' : ''}`}
										placement="top"
									>
										<span>{s?.skill?.label}</span>
									</Tooltip>
									{i < a.length - 1 ? ', ' : null}
								</span>
							))}
						{[1, 2, 3].filter(s => candidate[`domain_experience_${s}_id`]).length ? null : Utility.na}
					</>
				);
			},
		},
		{
			field: 'candidates.city',
			headerName: 'Location Preference',
			sortable: false,
			resizable: true,
			// noSort: true,
			width: 125,
			cellRenderer: ({ data: candidate, node }, ref) => {
				if (!candidate || !node) {
					return null;
				}

				return (
					<>
						{candidate?.work_location_preferences?.length ? (
							<p style={{ marginBottom: 0 }}>
								{Utility.getConsolidatedLocationPreferences(candidate?.work_location_preferences)?.join(', ') || ''}
							</p>
						) : null}

						{candidate?.state ? (
							<span className="color-muted">{`${candidate?.city || ''}, ${Utility.getFromObj(
								Utility.getIDFromObj(data.states, candidate?.state, 'name'),
								'abbreviation',
							)}`}</span>
						) : (
							Utility.na
						)}
						<br />
					</>
				);
			},
		},
		{
			field: 'candidate_owner.name',
			headerName: 'Owner',
			sortable: false,
			resizable: true,
			width: 125,
			cellRenderer: ({ data: candidate, node }, ref) => {
				if (!candidate || !node) {
					return null;
				}

				console.log({
					candidate,
					owners: data.owners,
					owner: candidate?.owner,
				});

				return (
					<>
						{candidate?.owner
							? data.owners.find(x => x.id === candidate?.owner || x.vendor_owner_id === candidate?.owner)?.name ||
							  'Unknown'
							: Utility.na}
					</>
				);
			},
		},
		{
			field: 'status.id',
			headerName: 'Status',
			sortable: true,
			resizable: true,
			width: 100,
			cellRenderer: ({ data: candidate, node }, ref) => {
				if (!candidate || !node) {
					return null;
				}

				return (
					<>
						{candidate?.candidate_status ? (
							<Chip
								size="small"
								className={`color-${Utility.getCandidateStatusColor(
									Utility.getItemName(data.candidateStatuses, candidate?.candidate_status, 'id'),
								)} no-margins`}
								label={Utility.getItemLabel(data.candidateStatuses, candidate?.candidate_status)}
							/>
						) : (
							Utility.na
						)}
					</>
				);
			},
		},
		{
			field: 'candidates.source.id',
			headerName: 'Source',
			sortable: true,
			resizable: true,
			width: 100,
			cellRenderer: ({ data: candidate, node }) => {
				if (!candidate || !node) {
					return null;
				}

				return (
					<>
						{candidate?.source ? (
							<Chip size="small" className={`color-grey-400 no-margins`} label={candidate?.source.label} />
						) : (
							Utility.na
						)}
					</>
				);
			},
		},
		{
			field: 'candidates.created_at',
			headerName: 'Created',
			width: 140,
			sortable: true,
			resizable: true,
			sort: 'desc',
			cellRenderer: ({ data: candidate, node }, ref) => {
				if (!candidate || !node) {
					return null;
				}

				return (
					<>
						{Utility.formatDate(candidate?.created_at)}
						<br />
						<span className="color-muted">{Utility.formatTime(candidate?.created_at)}</span>
					</>
				);
			},
		},
		{
			field: 'candidates.updated_at',
			headerName: 'Last Updated',
			width: 140,
			sortable: true,
			resizable: true,
			cellRenderer: ({ data: candidate, node }, ref) => {
				if (!candidate || !node) {
					return null;
				}

				return (
					<>
						{Utility.formatDate(candidate?.updated_at)}
						<br />
						<span className="color-muted">{Utility.formatTime(candidate?.updated_at)}</span>
					</>
				);
			},
		},
		{
			field: 'candidates.assessment_completed_at',
			headerName: 'Intake Completed',
			width: 150,
			sortable: true,
			resizable: true,
			cellRenderer: ({ data: candidate, node }, ref) => {
				if (!candidate || !node) {
					return null;
				}

				return (
					<>
						{/* <Icons.WorkOutline color="disabled" style={{ fontSize: 13 }} /> {candidate?.listing_matches_count}
					<br />
					<span className="color-muted">Job Matched</span> */}
						{candidate?.assessment_completed_at ? Utility.formatDate(candidate?.assessment_completed_at) : null}
					</>
				);
			},
		},
		{
			field: 'candidates.shared_match_count',
			headerName: 'Shared Matches',
			width: 125,
			sortable: true,
			resizable: true,
			cellRenderer: ({ data: candidate, node }, ref) => {
				if (!candidate || !node) {
					return null;
				}

				return (
					<>
						<span style={{ display: 'block' }}>{candidate?.shared_match_count || 0}</span>
					</>
				);
			},
		},
	];

	const [clearMenuAnchorEl, setClearMenuAnchorEl] = React.useState(null);
	const [tableKey, setTableKey] = React.useState(0);

	const handleClearMenuClick = event => {
		setClearMenuAnchorEl(event.currentTarget);
	};

	const handleClearMenuClose = () => {
		setClearMenuAnchorEl(null);
	};

	const handleClearSort = () => {
		// window.localStorage.setItem('jobs-table', null);
		handleClearMenuClose();
		setTableKey(x => x + 1);
	};
	const handleClearFilter = () => {
		handleClearMenuClose();
		_setFilter({ ...filter, filters: {} });

		if (gridApi) {
			// setGridKey(x => x + 1);
			gridApi.onFilterChanged();
		}
	};
	const handleClearAll = () => {
		handleClearMenuClose();
		handleClearSort();
		setTimeout(() => {
			handleClearFilter();
		}, 500);
	};

	const refreshTable = () => {
		setGridKey(x => x + 1);
	};

	return (
		<Page filterPadding={filter.isOpen}>
			<PageHeader.Header>
				<PageHeader.Left>
					<Typography variant="h1">Candidates</Typography>
				</PageHeader.Left>
				<PageHeader.Right>
					{selectedItems.length ? (
						<NavLink to={`/candidates/details?candidates=${selectedItems?.join(',')}`} className="color-inherit">
							<Button
								variant="contained"
								color="primary"
								// onClick={openSelectedItemOverview}
								startIcon={<Icons.Fullscreen />}
							>
								Open Overview ({selectedItems.length})
							</Button>
						</NavLink>
					) : null}
					<Button
						aria-controls="simple-menu"
						aria-haspopup="true"
						onClick={handleClearMenuClick}
						variant="outlined"
						color="primary"
						endIcon={<Icons.ArrowDropDown />}
					>
						Clear
					</Button>
					<Menu
						id="clear-menu"
						anchorEl={clearMenuAnchorEl}
						keepMounted
						open={Boolean(clearMenuAnchorEl)}
						onClose={handleClearMenuClose}
					>
						<MenuItem onClick={handleClearSort}>Clear Sort</MenuItem>
						<MenuItem onClick={handleClearFilter} disabled={!Object.keys(filter?.filters || {}).length}>
							Clear Filter
						</MenuItem>
						<MenuItem onClick={handleClearAll}>Clear All</MenuItem>
					</Menu>
					<Tooltip className="tooltip" title={`${numFilters} Filter${numFilters > 1 ? 's' : ''}`} placement="top">
						<Button
							style={{
								backgroundColor: numFilters > 0 ? 'rgba(76, 159, 255,.3)' : 'transparent',
							}}
							variant="outlined"
							color="primary"
							onClick={filter.isOpen ? closeFilter : openFilter}
							startIcon={<Icons.FilterList />}
						>
							Filter
						</Button>
					</Tooltip>
					<Button
						variant="contained"
						color="primary"
						onClick={() =>
							store.dispatch(
								SiteActions.showModal(<CandidateSyncModal category={menu.category} onSync={refreshTable} />),
							)
						}
						startIcon={<Icons.Sync />}
					>
						Sync Candidate
					</Button>
				</PageHeader.Right>
			</PageHeader.Header>

			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-end',
					paddingBottom: 15,
					marginTop: -20,
				}}
			>
				<Checkbox checked={!!filterIntakeCompleted} onChange={ev => setFilterIntakeCompleted(ev.target.checked)} />
				<Typography variant="body2" style={{ marginBottom: 0 }}>
					Intake Completed
				</Typography>
			</div>

			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}

			{enableDynamicFilters && filter.isDynamic ? (
				<CandidateDynamicFilters
					enableSavedFilters
					savedFilters={savedFilters}
					setSavedFilters={setSavedFilters}
					activeSaveFilterId={activeSaveFilterId}
					setActiveSaveFilterId={setActiveSaveFilterId}
					onClose={closeFilter}
					onReset={() => setFilter({ ...filter, filters: {} })}
					data={data}
					onUpdate={onFilterUpdate}
					filter={filter}
					setFilter={setFilter}
					displayFilters={[
						'owner',
						'name',
						'vendor_candidate_id',
						'professional_experience_years_range',
						'salary_expectation_type_range',
						'location_city',
						'location_state',
						'work_location_preferences',
						'desired_locations',
						'resume',
						'professional_category_id',
						'specialty_id',
						'skills',
						'get_the_job_skills',
						'industries',
						'recent_leadership_role_id',
						'total_leadership_years_range',
						'desired_position',
						'current_title',
						'current_employer',
						'work_visa_type_id',
						'status',
						'last_profile_update_range',
						'assessment_completed_range',
						// 'score_range',
						'source',
					]}
				/>
			) : !enableDynamicFilters && !filter.isDynamic ? (
				<CandidateFilters
					enableSavedFilters
					savedFilters={savedFilters}
					setSavedFilters={setSavedFilters}
					activeSaveFilterId={activeSaveFilterId}
					setActiveSaveFilterId={setActiveSaveFilterId}
					onClose={closeFilter}
					onReset={() => setFilter({ ...filter, filters: {} })}
					data={data}
					updateFilters={updateFilters}
					onUpdate={onFilterUpdate}
					filter={filter}
					setFilter={setFilter}
					displayFilters={[
						'owner',
						'name',
						'vendor_candidate_id',
						'professional_experience_years_range',
						'salary_expectation_type_range',
						'location_city',
						'location_state',
						'work_location_preferences',
						'desired_locations',
						'resume',
						'professional_category_id',
						'specialty_id',
						'skills',
						'get_the_job_skills',
						'industries',
						'recent_leadership_role_id',
						'total_leadership_years_range',
						'desired_position',
						'current_title',
						'current_employer',
						'work_visa_type_id',
						'status',
						'last_profile_update_range',
						'assessment_completed_range',
						// 'score_range',
						'source',
					]}
				/>
			) : null}

			<div className="ag-theme-balham" style={{ height: 'calc(100% - 120px)', width: '100%' }}>
				{dataIsLoaded ? (
					<AgGridReact
						key={gridKey}
						onGridReady={({ api }) => {
							setGridApi(api);
							// api.sizeColumnsToFit();
							const sortModel = [{ colId: 'candidates.created_at', sort: 'desc' }];
							// api.setSortModel(sortModel);
							// api.applyColumnState({
							// 	state: sortModel,
							// });
						}}
						rowModelType="infinite"
						datasource={datasource}
						rowHeight={60}
						// pagination
						// paginationPageSize={15}
						// loading
						loadingCellRenderer={() => {
							console.log('Loading...');
							return <div>Loading...</div>;
						}}
						loadingCellRendererParams={{}}
						cacheBlockSize={10}
						ref={gridRef} // Ref for accessing Grid's API
						// rowData={tableData} // Row Data for Rows
						columnDefs={columnDefs} // Column Defs for Columns
						// defaultColDef={defaultColDef} // Default Column Properties
						animateRows // Optional - set to 'true' to have rows animate when sorted
						rowSelection="multiple" // Options - allows click selection of rows
						onCellClicked={() => {}} // Optional - registering for Grid Event
						onSelectionChanged={({ api }) => {
							const selectedRows = api.getSelectedRows();
							setSelectedItems(selectedRows.map(x => x.id));
						}}
					/>
				) : null}
			</div>

			{/* <Table
				stickyStateKey="candidates-table-v9"
				onUpdate={CandidatesAPI.getCandidates}
				filters={filter.filters}
				defaultOrderBy="candidates.created_at"
				defaultSortDirection="desc"
				format={formatData}
				headers={headers}
				ref={table}
				// checkbox
				resetStateKey={tableKey}
			/> */}

			<Menu
				anchorEl={menu.anchor}
				open={Boolean(menu.anchor)}
				onClose={() => setMenu({ ...menu, anchor: null })}
				keepMounted
			>
				<NavLink to={`/candidate/${menu.candidate?.id}`} className="color-inherit" target="_blank">
					<MenuItem>
						<ListItemIcon>
							<Icons.Person color="primary" />
						</ListItemIcon>
						View Candidate
					</MenuItem>
				</NavLink>
				<NavLink to={`/candidate/${menu.candidate.id}/matches`}>
					<MenuItem>
						<ListItemIcon>
							<Icons.Search color="primary" />
						</ListItemIcon>
						View Suggested Matches
					</MenuItem>
				</NavLink>
				{/* <NavLink to={`/candidate/${menu.candidate.id}/match-testing`}>
					<MenuItem>
						<ListItemIcon>
							<Icons.TrendingUp color="primary" />
						</ListItemIcon>
						Testing
					</MenuItem>
				</NavLink> */}
				<MenuItem disabled={menu.candidate?.user?.claimed_at} onClick={sendAssessment}>
					<ListItemIcon>
						<Icons.Assignment color="primary" />
					</ListItemIcon>
					Portal Invitation
				</MenuItem>
				{/* <NavLink to={`/candidate/${menu.candidate?.id}/edit`} className="color-inherit">
					<MenuItem>
						<ListItemIcon>
							<Icons.Create color="primary" />
						</ListItemIcon>
						Edit Candidate
					</MenuItem>
				</NavLink> */}
				{/* <MenuItem onClick={sendAssessment}>
					<ListItemIcon>
						<Icons.Assignment color="primary" />
					</ListItemIcon>
					Send Access Email
				</MenuItem> */}
				<MenuItem
					onClick={() => {
						setMenu({ ...menu, anchor: null });
						store.dispatch(
							SiteActions.showModal(<CandidateDeleteModal candidate={menu.candidate} onDelete={refreshTable} />),
						);
					}}
				>
					<ListItemIcon>
						<Icons.Delete color="secondary" />
					</ListItemIcon>
					Delete Candidate
				</MenuItem>
			</Menu>
		</Page>
	);
};

export default Candidates;
