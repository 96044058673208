const createDynamicElasticQuery = ({ object = 'candidate', currentFilter, query, objectPrefix = '' }) => {
	if (object !== 'candidate') {
		return null;
	}

	console.log({
		currentFilter,
	});

	// if (currentFilter.filters?.assessment_completed_min || currentFilter.filters?.assessment_completed_max) {
	// 	query.bool.must.push({
	// 		range: {
	// 			assessment_completed_at: {
	// 				gte: currentFilter.filters?.assessment_completed_min
	// 					? currentFilter.filters?.assessment_completed_min
	// 					: '01/01/1970',
	// 				lte: currentFilter.filters?.assessment_completed_max
	// 					? currentFilter.filters?.assessment_completed_max
	// 					: '12/31/2099',
	// 			},
	// 		},
	// 	});
	// }

	if (currentFilter.filters.resume) {
		currentFilter.filters.resume.values.forEach(filterValue => {
			if (filterValue.value) {
				query.bool.must.push({
					knn: {
						field: 'resume_vector',
						// k: 10,
						num_candidates: 10000,
						query_vector_builder: {
							text_embedding: {
								model_id: 'openai_embeddings',
								model_text: filterValue.value,
							},
						},
						similarity: 0.3,
					},
				});
			}
		});
	}

	if (currentFilter.filters?.portal_intake_completed) {
		currentFilter.filters?.portal_intake_completed?.values?.forEach(filterValue => {
			if (filterValue.value) {
				query.bool[filterValue.value === 1 ? 'must' : 'must_not'].push({
					exists: {
						field: `${objectPrefix}assessment_completed_at`,
					},
				});
			}
		});
	}
	if (currentFilter.filters?.profile_last_updated) {
		currentFilter.filters?.profile_last_updated?.values?.forEach(filterValue => {
			if (filterValue.min || filterValue.max) {
				query.bool.must.push({
					range: {
						[`${objectPrefix}last_profile_update`]: {
							gte: filterValue.min ? filterValue.min : '1970-01-01T00:00:00.000Z',
							lte: filterValue.max ? filterValue.max : '2099-12-31T00:00:00.000Z',
						},
					},
				});
			}
		});
	}
	if (currentFilter.filters?.date_intake_completed) {
		currentFilter.filters?.date_intake_completed?.values?.forEach(filterValue => {
			if (filterValue.min || filterValue.max) {
				query.bool.must.push({
					range: {
						[`${objectPrefix}assessment_completed_at`]: {
							gte: filterValue.min ? filterValue.min : '1970-01-01T00:00:00.000Z',
							lte: filterValue.max ? filterValue.max : '2099-12-31T00:00:00.000Z',
						},
					},
				});
			}
		});
	}

	if (currentFilter.filters?.date_intake_completed) {
		currentFilter.filters?.date_intake_completed?.values?.forEach(filterValue => {
			if (filterValue.min || filterValue.max) {
				query.bool.must.push({
					range: {
						[`${objectPrefix}assessment_completed_at`]: {
							gte: filterValue.min ? filterValue.min : '1970-01-01T00:00:00.000Z',
							lte: filterValue.max ? filterValue.max : '2099-12-31T00:00:00.000Z',
						},
					},
				});
			}
		});
	}

	if (currentFilter.filters?.record_completeness) {
		currentFilter.filters?.record_completeness?.values?.forEach(filterValue => {
			if (filterValue.value?.length) {
				query.bool.must.push({
					range: {
						[`${objectPrefix}completeness`]: {
							gte: filterValue.value,
						},
					},
				});
			}
		});
	}

	if (currentFilter.filters?.education) {
		currentFilter.filters?.education?.values?.forEach(filterValue => {
			if (filterValue.value?.length) {
				query.bool.must.push({
					terms: {
						[`${objectPrefix}education_type.id`]: filterValue.value,
					},
				});
			}
		});
	}

	if (currentFilter.filters?.areas_of_study) {
		currentFilter.filters?.areas_of_study?.values?.forEach(filterValue => {
			if (filterValue.value?.length) {
				query.bool.must.push({
					terms: {
						[`${objectPrefix}study_types.id`]: filterValue.value,
					},
				});
			}
		});
	}

	if (currentFilter.filters?.visa_sponsorship) {
		currentFilter.filters?.visa_sponsorship?.values?.forEach(filterValue => {
			if (filterValue.value) {
				query.bool.must.push({
					term: {
						[`${objectPrefix}work_visa_type_id`]: filterValue.value,
					},
				});
			}
		});
	}
	if (currentFilter.filters?.work_environment) {
		currentFilter.filters?.work_environment?.values?.forEach(filterValue => {
			if (filterValue.value?.length) {
				query.bool.must.push({
					terms: {
						[`${objectPrefix}work_location_preferences.id`]: filterValue.value,
					},
				});
			}
		});
	}
	if (currentFilter.filters?.role_priorities) {
		currentFilter.filters?.role_priorities?.values?.forEach(filterValue => {
			if (filterValue.value?.length) {
				query.bool.must.push({
					terms: {
						[`${objectPrefix}job_priority_types.value_proposition_type.id`]: filterValue.value,
					},
				});
			}
		});
	}
	if (currentFilter.filters?.role_type) {
		currentFilter.filters?.role_type?.values?.forEach(filterValue => {
			if (filterValue.value?.length) {
				query.bool.must.push({
					nested: {
						path: 'employment_preferences',
						query: {
							terms: {
								[`${objectPrefix}employment_preferences.id`]: filterValue.value,
							},
						},
					},
				});
			}
		});
	}
	if (currentFilter.filters?.desired_locations) {
		currentFilter.filters?.desired_locations?.values?.forEach(filterValue => {
			if (filterValue.value?.length) {
				query.bool.must.push({
					terms: {
						[`${objectPrefix}desired_locations.state.id`]: filterValue.value,
					},
				});
			}
		});
	}
	if (currentFilter.filters?.state) {
		currentFilter.filters?.state?.values?.forEach(filterValue => {
			if (filterValue.value) {
				query.bool.must.push({
					terms: {
						[`${objectPrefix}state`]: filterValue.value,
					},
				});
			}
		});
	}

	if (currentFilter.filters?.leadership_role) {
		currentFilter.filters?.leadership_role?.values?.forEach(filterValue => {
			query.bool.must.push({
				terms: {
					[`${objectPrefix}recent_leadership_role_id`]: filterValue.value,
				},
			});
		});
	}

	if (currentFilter.filters?.total_leadership_years_range) {
		currentFilter.filters.total_leadership_years_range.values.forEach(filterValue => {
			console.log({
				filterValue,
			});
			query.bool.must.push({
				range: {
					[`${objectPrefix}total_leadership_years`]: {
						gte: filterValue?.min ? parseFloat(filterValue?.min) : 0,
						lte: filterValue?.max ? parseFloat(filterValue?.max) : 99,
					},
				},
			});
		});
	}

	if (currentFilter.filters?.professional_experience_years_range) {
		currentFilter.filters.professional_experience_years_range.values.forEach(filterValue => {
			console.log({
				filterValue,
			});
			query.bool.must.push({
				range: {
					[`${objectPrefix}professional_experience_years`]: {
						gte: filterValue?.min ? parseFloat(filterValue?.min) : 0,
						lte: filterValue?.max ? parseFloat(filterValue?.max) : 99,
					},
				},
			});
		});
	}

	if (currentFilter.filters?.status) {
		currentFilter.filters.status.values.forEach(filterValue => {
			if (filterValue?.value?.length) {
				query.bool.must.push({
					terms: {
						[`${objectPrefix}candidate_status`]: filterValue?.value,
					},
				});
			}
		});
	}

	if (currentFilter.filters?.owner) {
		currentFilter.filters.owner?.values?.forEach(filterValue => {
			if (filterValue?.value?.length) {
				query.bool.must.push({
					terms: {
						[`${objectPrefix}owner`]: filterValue?.value,
					},
				});
			}
		});
	}
	if (currentFilter.filters.name) {
		currentFilter.filters.name.values.forEach(filterValue => {
			const nameQuery = filterValue?.value;

			if (!nameQuery?.length) {
				return null;
			}

			const filterQuery = {
				bool: {
					should: [],
					minimum_should_match: 1,
				},
			};
			// Split the name
			const firstName = nameQuery?.split(' ')[0];
			const lastName = nameQuery?.split(' ')[1];

			console.log({
				firstName,
				lastName,
			});
			if (firstName?.length && lastName?.length) {
				filterQuery.bool.should = filterQuery.bool.should.concat([
					{
						wildcard: {
							[`${objectPrefix}first_name`]: {
								value: `*${firstName}*`,
								case_insensitive: true,
							},
						},
					},
					{
						wildcard: {
							[`${objectPrefix}last_name`]: {
								value: `*${lastName}*`,
								case_insensitive: true,
							},
						},
					},
				]);
				filterQuery.bool.minimum_should_match = 2;
			} else {
				filterQuery.bool.should = filterQuery.bool.should.concat([
					{
						wildcard: {
							[`${objectPrefix}first_name`]: {
								value: `*${nameQuery}*`,

								case_insensitive: true,
							},
						},
					},
					{
						wildcard: {
							[`${objectPrefix}last_name`]: {
								value: `*${nameQuery}*`,

								case_insensitive: true,
							},
						},
					},
					{
						wildcard: {
							[`${objectPrefix}id`]: `*${nameQuery}*`,
						},
					},
					{
						wildcard: {
							[`${objectPrefix}vendor_candidate_id`]: `*${nameQuery}*`,
						},
					},
				]);
				filterQuery.bool.minimum_should_match = 1;
			}

			query.bool.must.push(filterQuery);
			return null;
		});
	}

	if (currentFilter.filters?.skill_story) {
		// Loop through each skill block filter
		currentFilter.filters?.skill_story?.values?.forEach(skillFilter => {
			const { type, secondaryType } = skillFilter;

			// If the filter has skills selected...
			if (skillFilter?.value?.length) {
				skillFilter?.value?.forEach(x => {
					const nestedQuery = {
						nested: {
							path: [`${objectPrefix}candidate_skills`],
							query: {
								bool: {
									must: [],
								},
							},
						},
					};

					const targetArray = nestedQuery.nested.query.bool.must;

					// Filter for the skill
					targetArray.push({
						term: {
							[`${objectPrefix}candidate_skills.skill_id`]: x,
						},
					});

					// Filter for the skill experience level
					if (secondaryType === 'hero') {
						targetArray.push({
							term: {
								'candidate_skills.get_the_job': true,
							},
						});
					} else if (secondaryType === 'enjoy') {
						targetArray.push({
							term: {
								'candidate_skills.enjoy_the_job': true,
							},
						});
					} else if (secondaryType === 'learn') {
						targetArray.push({
							term: {
								'candidate_skills.learn_on_job': true,
							},
						});
					} else if (secondaryType === 'improve') {
						targetArray.push({
							term: {
								'candidate_skills.improve_on_job': true,
							},
						});
					}

					// Handle the type of filter
					query.bool[
						type === 'require-all'
							? 'must'
							: type === 'include-any'
							? 'should'
							: type === 'exclude'
							? 'must_not'
							: 'must'
					].push(nestedQuery);
				});
			}
		});
	}

	if (currentFilter.filters?.skills) {
		// Loop through each skill block filter
		currentFilter.filters?.skills?.values?.forEach(skillFilter => {
			const { type, secondaryType } = skillFilter;

			// If the filter has skills selected...
			if (skillFilter?.value?.length) {
				skillFilter?.value?.forEach(x => {
					const nestedQuery = {
						nested: {
							path: [`${objectPrefix}candidate_skills`],
							query: {
								bool: {
									must: [],
								},
							},
						},
					};

					const targetArray = nestedQuery.nested.query.bool.must;

					// Filter for the skill
					targetArray.push({
						term: {
							[`${objectPrefix}candidate_skills.skill_id`]: x,
						},
					});

					// Filter for the skill experience level
					if (['best', 'advanced', 'intermediate', 'basic'].includes(secondaryType)) {
						targetArray.push({
							term: {
								'candidate_skills.experience_level.name': secondaryType,
							},
						});
					}

					// Handle the type of filter
					query.bool[
						type === 'require-all'
							? 'must'
							: type === 'include-any'
							? 'should'
							: type === 'exclude'
							? 'must_not'
							: 'must'
					].push(nestedQuery);
				});
			}
		});
	}

	return null;
};

export default createDynamicElasticQuery;
