import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Button, Typography, Grid } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import StickyHeader from '../StickyHeader';
import LocationPinIcon from '../icons/SectionIcons/LocationPinIcon';
import LabeledField from '../form/LabeledField';
import ListingStatusChip from '../matching/ListingStatusChip';
import ExpandableTypography from '../form/ExpandableTypography';
import * as CandidatesAPI from '../../scripts/candidates';
import Job1Background from '../../img/match-share-backgrounds/job-1.png';
import CaseIcon from '../icons/SectionIcons/CaseIcon';
import GlobeIcon from '../icons/GlobeIcon';
import * as Utility from '../../scripts/utility';
import { palette } from '../../BobcatTheme';
import ViewSkillsBlock from '../../pages/jobs/JobBlocks/ViewSkillsBlock';
import { Panel } from '../Panel';
import { SkillChip } from '../../pages/candidates/CandidateBlocks/ViewSkillsBlock';
import { InterestIcon } from './RateJobClientInterestModal';
import JobViewDetailsBlock from '../../pages/jobs/JobBlocks/ViewDetailsBlock';
import JobViewExperienceBlock from '../../pages/jobs/JobBlocks/ViewExperienceBlock';
import JobViewLocationBlock from '../../pages/jobs/JobBlocks/ViewLocationBlock';
import JobViewDescriptionBlock from '../../pages/jobs/JobBlocks/ViewDescriptionBlock';

import CompanyViewDetailsBlock from '../../pages/jobs/CompanyBlocks/ViewDetailsBlock';
import CompanyViewFinancialBlock from '../../pages/jobs/CompanyBlocks/ViewFinancialBlock';
import CompanyViewBenefitsBlock from '../../pages/jobs/CompanyBlocks/ViewBenefitsBlock';
import CompanyViewProductsServicesBlock from '../../pages/jobs/CompanyBlocks/ViewProductsServicesBlock';
import useVerifyAuthorization from '../../hooks/useVerifyAuthorization';

const SharedJobModal = ({ match, candidateId, refetchMatches, hideRating, adminView, ...props }) => {
	const { data } = useSelector(state => state.site, shallowEqual);
	const [activeTab, setActiveTab] = useState('skills');

	const candidateID = candidateId || match?.candidate_id;

	// For admins: manually fetch the listing and company.
	const [overrideListing, setOverrideListing] = useState(props?.overrideListing);
	useEffect(() => {
		if (match?.listing?.id || match?.listing_id) {
			CandidatesAPI.getCandidateMatch({
				candidateId: candidateID,
				matchId: match?.id,
			}).then(newMatch => {
				const newListing = newMatch?.listing;
				if (newListing) {
					setOverrideListing(newListing);
				}
				return null;
			});
		}
	}, []);

	const listing = overrideListing || match?.listing;
	const company = overrideListing?.client_corporation || listing?.client_corporation;
	const isSalaryShared = match?.reveal_compensation;
	const isCompanyShared = match?.reveal_company;
	const isFullAccess = isSalaryShared && isCompanyShared;

	const { isAuthorized: isAuthorizedAdmin, loading: isLoadingAuthorization } = useVerifyAuthorization({
		role: 'admin',
	});

	useEffect(() => {
		// Don't trigger a view if the current user is an admin.
		if (!isLoadingAuthorization && !isAuthorizedAdmin) {
			if (match?.id && candidateId) {
				// Set the match as viewed.
				CandidatesAPI.postCandidateMatchView({
					candidateId,
					matchId: match?.id,
				})
					.then(() => {
						console.log('Marked match as viewed.');

						refetchMatches({
							candidateId,
						});
					})
					.catch(console.log);
			}
		}
	}, [!!match?.id, candidateId, isLoadingAuthorization, isAuthorizedAdmin]);

	if (!listing) {
		return null;
	}

	return (
		<div
			style={{
				position: 'relative',
			}}
		>
			<div>
				<StickyHeader
					offset={200}
					style={{
						width: 750,
						left: '50%',
						right: 'auto',
						marginLeft: -375,
					}}
					scrollElement={document.querySelector('.modal')}
				>
					<Typography
						variant="body2"
						style={{
							color: '#fff',
							marginLeft: -5,
							fontWeight: 700,
							fontSize: 14,
							textWrap: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
					>
						{listing.position_title || `Job #${listing?.id}`}
						{isCompanyShared ? ` | ${listing.company}` : ''}
						{isSalaryShared
							? ` | ${Utility.formatMoney(listing.salary_rate_min, { hideDecimal: true })} -
						${Utility.formatMoney(listing.salary_rate_max, { hideDecimal: true })}`
							: ''}
					</Typography>
				</StickyHeader>
			</div>
			<div
				style={{
					position: 'absolute',
					top: 20,
					right: -40,
					zIndex: 100,
				}}
			>
				<ListingStatusChip listing={listing} />
			</div>
			<div
				style={{
					margin: '-75px -67px 0 -67px',
					padding: '75px 67px 60px 67px',
					background: `url(${Job1Background}) center center no-repeat`,
					backgroundColor: '#013B7F',
					backgroundSize: 'cover',
					minHeight: 200,
					color: '#fff',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					borderTopLeftRadius: 5,
					borderTopRightRadius: 5,
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'flex-start',
						justifyContent: 'center',
						paddingBottom: 5,
						gap: listing?.position_title?.length > 20 ? 0 : 10,
					}}
				>
					<CaseIcon size={28} color="var(--blue-100)" />
					<Typography
						variant="h2"
						className="font-lato-bold"
						style={{
							textAlign: 'center',
							fontSize: 20,
							color: '#fff',
							marginTop: listing?.position_title?.length > 20 ? 0 : 2,
						}}
					>
						{listing.position_title} at{' '}
						{isCompanyShared ? (
							<>{listing.company}</>
						) : (
							<span
								style={{
									filter: 'blur(5px)',
									webkitFilter: 'blur(5px)',
									userSelect: 'none',
									WebkitUserSelect: 'none',
									MozUserSelect: 'none',
									msUserSelect: 'none',
								}}
								onCopy={e => e.preventDefault()}
							>
								CompanyName
							</span>
						)}
					</Typography>
				</div>
				{listing.professional_experience_years ? (
					<Typography style={{ color: 'var(--blue-100)' }}>
						{listing.professional_experience_years}+ years experience
					</Typography>
				) : null}
				{listing.total_leadership_years ? (
					<Typography style={{ color: 'var(--blue-100)' }}>
						{listing.total_leadership_years}+ years leadership experience
					</Typography>
				) : null}
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Typography style={{ color: '#fff', fontSize: 20, fontWeight: 500 }}>
						{isSalaryShared ? (
							<span>
								{Utility.formatMoney(listing.salary_rate_min, { hideDecimal: true })} -{' '}
								{Utility.formatMoney(listing.salary_rate_max, { hideDecimal: true })}
							</span>
						) : (
							<span
								style={{
									filter: 'blur(5px)',
									webkitFilter: 'blur(5px)',
									userSelect: 'none',
									WebkitUserSelect: 'none',
									MozUserSelect: 'none',
									msUserSelect: 'none',
								}}
								onCopy={e => e.preventDefault()}
							>
								$hid,den - $hid,den
							</span>
						)}
					</Typography>

					{/* If the job offers a work visa, display it. */}
					{listing.work_visa_type_id === 1 ? (
						<>
							<div style={{ width: 15 }} />
							<GlobeIcon color="#fff" />
							<div style={{ width: 5 }} />
							<Typography
								style={{
									color: '#fff',
									fontSize: 20,
									fontWeight: 500,
								}}
							>
								Visa Offered
							</Typography>
						</>
					) : null}
				</div>

				<div style={{ height: 10 }} />
				<Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
					{(listing.skills || [])
						.filter(x => x.hero_skill)
						.map(skill => (
							<SkillChip
								baseColor="blue"
								key={skill?.id}
								colorOverride="#fff"
								classOverride="white-text"
								skill={skill}
								style={{
									background: '#3290ff',
									filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15))',
								}}
							/>
						))}
				</Grid>
				{overrideListing && (
					<>
						<Grid container spacing={2} alignContent="center" justifyContent="center">
							{listing?.industries?.length ? (
								<Grid item xs={4}>
									<HighlightCard
										color="blue"
										icon={<Icons.Business size={20} style={{ color: 'var(--blue-600)' }} />}
										// title="Industry"
										title={`${listing?.industries?.[0]?.label?.replace('Technology - ', '')}${
											listing?.industries?.length > 1 ? ' +' : ''
										}`}
									/>
								</Grid>
							) : null}
							{listing?.client_corporation?.business_sizes?.length ? (
								<Grid item xs={4}>
									<HighlightCard
										color="blue"
										icon={<Icons.AccountCircle size={20} style={{ color: 'var(--blue-600)' }} />}
										// title="Business Size"
										title={listing?.client_corporation?.business_sizes?.[0]?.label}
									/>
								</Grid>
							) : null}

							{listing?.work_location_preferences?.length ? (
								<Grid item xs={4}>
									<HighlightCard
										color="blue"
										icon={<LocationPinIcon size={20} color="var(--blue-600)" />}
										// title="Work Location"
										title={Utility.getConsolidatedLocationPreferences(listing?.work_location_preferences)?.join(' | ')}
									/>
								</Grid>
							) : null}

							{listing?.client_corporation?.year_founded ? (
								<Grid item xs={4}>
									<HighlightCard
										color="blue"
										icon={<Icons.CalendarToday size={20} style={{ color: 'var(--blue-600)' }} />}
										title={`Year Founded: ${listing?.client_corporation?.year_founded || 'N/A'}`}
									/>
								</Grid>
							) : null}

							{listing?.role ? (
								<Grid item xs={4}>
									<HighlightCard
										color="blue"
										icon={<CaseIcon size={20} color="var(--blue-600)" />}
										// title="Type"
										title={listing?.role?.label}
									/>
								</Grid>
							) : null}
							{listing?.work_visa_types ? (
								<Grid item xs={4}>
									<HighlightCard
										color="blue"
										icon={<Icons.Language size={20} style={{ color: 'var(--blue-600)' }} />}
										// title="Work Visa Sponsorship"
										title={`Work Visa: ${listing?.work_visa_types?.label}`}
									/>
								</Grid>
							) : null}
						</Grid>
					</>
				)}
			</div>
			<div
				style={{
					minHeight: 150,
					backgroundColor: '#fff',
					margin: '0 -67px 0 -67px',
					padding: '25px 67px 25px 67px',
				}}
			>
				<LabeledField label="Company Summary" className="color-muted" removeMargin close style={{ minWidth: 120 }}>
					<ExpandableTypography text={company?.company_summary_external} />
				</LabeledField>
				<div style={{ height: 20 }} />
				<LabeledField
					label="Job Summary (Abstract)"
					className="color-muted"
					removeMargin
					close
					style={{ minWidth: 120 }}
				>
					<ExpandableTypography text={listing.abstract} />
				</LabeledField>
			</div>
			<div
				style={{
					backgroundColor: 'var(--grey-50)',
					paddingTop: 5,
					paddingBottom: 5,
				}}
			>
				<CustomTabBar
					activeTab={activeTab}
					setActiveTab={newTab => {
						setActiveTab(newTab);
					}}
					tabs={[
						{
							id: 'skills',
							label: 'Skills',
						},
						{
							id: 'job-info',
							label: 'Job Info',
						},
						{
							id: 'company-info',
							label: 'Company Info',
						},
						{
							id: 'job-description',
							label: 'Job Description',
						},
					]}
				/>
				<div style={{ height: 20 }} />

				{activeTab === 'skills' ? (
					<Panel topBarColor="blue">
						<ViewSkillsBlock job={listing} setJob={null} data={data} groupByRanking disableEdit />
					</Panel>
				) : activeTab === 'job-info' ? (
					<>
						<Panel topBarColor="blue">
							<JobViewDetailsBlock
								disableEdit
								job={listing}
								setJob={null}
								data={data}
								comparisonCandidate={null}
								isLimited={!isSalaryShared}
							/>
						</Panel>
						<Panel topBarColor="blue">
							<JobViewExperienceBlock disableEdit job={listing} setJob={null} data={data} comparisonCandidate={null} />
						</Panel>
						<Panel topBarColor="blue">
							<JobViewLocationBlock disableEdit job={listing} setJob={null} comparisonCandidate={null} data={data} />
						</Panel>
					</>
				) : activeTab === 'company-info' ? (
					<>
						<Panel topBarColor="blue">
							<CompanyViewDetailsBlock
								job={listing}
								setJob={null}
								company={company}
								setCompany={null}
								data={data}
								disableEdit
								condensedColumns
							/>
						</Panel>
						{isCompanyShared && (
							<Panel topBarColor="blue">
								<CompanyViewProductsServicesBlock
									job={listing}
									setJob={null}
									company={company}
									setCompany={null}
									data={data}
									disableEdit
								/>
							</Panel>
						)}
						<Panel topBarColor="blue">
							<CompanyViewBenefitsBlock
								job={listing}
								setJob={null}
								company={company}
								setCompany={null}
								data={data}
								disableEdit
							/>
						</Panel>
						{isFullAccess && (
							<Panel topBarColor="blue">
								<CompanyViewFinancialBlock
									job={listing}
									setJob={null}
									company={company}
									setCompany={null}
									data={data}
									disableEdit
								/>
							</Panel>
						)}
					</>
				) : activeTab === 'job-description' ? (
					<Panel topBarColor="blue">
						<JobViewDescriptionBlock job={listing} setJob={null} data={data} disableEdit />
					</Panel>
				) : null}
			</div>
			{!hideRating && (
				<InterestRatingBlock
					match={match}
					onSave={async levelId => {
						try {
							await CandidatesAPI.patchCandidateMatchInterest({
								candidateId,
								matchId: match?.id,
								interestLevelId: levelId,
							});
							await refetchMatches({ candidateId });
						} catch (e) {
							console.log(e);
						}
						console.log('Saving!');
					}}
				/>
			)}
		</div>
	);
};

export const HighlightCard = ({ title, value, icon, color, subtitle }) => {
	return (
		<div
			style={{
				height: 75,
				backgroundColor: color === 'blue' ? '#EEF6FF' : '#F2FDFC',
				borderRadius: 8,
				padding: 8,
				textAlign: 'center',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			{icon}
			{!title || !subtitle ? <div style={{ height: 5 }} /> : null}
			<Typography
				variant="body2"
				style={{
					color: color === 'blue' ? '#135C94' : '#20867F',
					fontWeight: 600,
					fontSize: 13,
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					width: '100%',
				}}
			>
				{title}
			</Typography>
			<Typography
				variant="body2"
				style={{
					color: color === 'blue' ? '#2095EF' : '#20867F',
					fontWeight: 600,
					fontSize: 13,
					marginTop: 0,
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					width: '100%',
				}}
			>
				{subtitle}
			</Typography>
		</div>
	);
};

const CustomTabBar = ({ activeTab, setActiveTab, tabs }) => {
	return (
		<div
			style={{
				borderBottom: '1px solid #ddd',
				paddingTop: 15,
				// paddingLeft: 40,
			}}
		>
			{tabs?.map((tab, index) => {
				const isActive = tab?.id === activeTab;

				return (
					<div
						style={{
							borderBottom: isActive ? `2px solid ${palette?.primary?.main}` : `2px solid transparent`,
							marginLeft: 0, // index === 0 ? -28 : 0,
							display: 'inline-block',
							marginRight: 20,
						}}
					>
						<Button
							color="primary"
							className="no-border-radius"
							onClick={() => {
								setActiveTab(tab?.id);
							}}
							style={{
								marginLeft: 0,
								marginRight: 0,
								paddingLeft: 0,
								paddingRight: 0,
								background: 'transparent',
								minWidth: 0,
							}}
						>
							<Typography
								variant="body"
								style={{ marginRight: 0, color: isActive ? '' : '#34414E', fontWeight: isActive ? 700 : 300 }}
							>
								{tab?.label}
							</Typography>
						</Button>
					</div>
				);
			})}
		</div>
	);
};

const InterestRatingBlock = ({ match, onSave }) => {
	const { data } = useSelector(state => state.site, shallowEqual);
	const [selectedInterestLevelId, setSelectedInterestLevelId] = useState(match?.interest_level_id);
	const [isLoading, setLoading] = useState(false);

	return (
		<div
			style={{
				position: 'fixed',
				padding: 15,
				backgroundColor: '#fff',
				// the position is calculated based on the width of the shared job modal (width is 750px and centered on screen)
				left: 'calc(50% + 375px + 15px)',
				top: 'calc(50vh - 200px)',
				borderRadius: 8,
				width: 190,
			}}
		>
			{[...(data?.interestLevels || [])]
				.reverse()
				.slice(0, 4)
				.map((level, index) => (
					// eslint-disable-next-line jsx-a11y/no-static-element-interactions
					<div
						key={level?.id}
						className="interest-level"
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							cursor: 'pointer',
							padding: 8,
							opacity: selectedInterestLevelId === level.id ? 1 : 0.7,
						}}
						onClick={async () => {
							if (!isLoading) {
								try {
									setLoading(true);
									setSelectedInterestLevelId(level.id);
									await onSave(level.id);
								} catch (e) {
									console.log(e);
								} finally {
									setLoading(false);
								}
							}
						}}
					>
						<div style={{ width: 28, height: 28, flexGrow: 0 }}>
							<InterestIcon
								interestLevel={level}
								interestLevelId={level.id}
								size={28}
								level={data.interestLevels?.length - index}
								color={selectedInterestLevelId === level.id ? 'var(--primaryColor)' : 'var(--mutedTextColor)'}
							/>
						</div>
						<div style={{ width: 10 }} />
						<Typography variant="body2" style={{ marginTop: '0.5rem' }}>
							{level?.label}
						</Typography>
					</div>
				))}
		</div>
	);
};

SharedJobModal.propTypes = {};

SharedJobModal.defaultProps = {};

export default SharedJobModal;
